import React, { useEffect, useRef,useState,useContext } from 'react';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

import Context from "../../Context/Context";
import { refreshToken, sendGetRequest } from "../../Helper/helper";

import coinmarketcap from "../../Assets/coinmarketcap.svg";
import coinmarketcap1 from "../../Assets/coinmarketcap.png";
import athcoinindex from "../../Assets/athcoinindex.png";
import coingecko from "../../Assets/coingecko.png";
import cryptocoinworld from "../../Assets/cryptocoinworld.png";


SwiperCore.use([Autoplay, Pagination, Navigation]);

const Adds = () => {

  const [data, setData] = useState();
  const { states } = useContext(Context);

  const dmode = states.dmode;

  useEffect(() => {
    (async () => {
      const res = await sendGetRequest("global");
      res.refresh_token && refreshToken(res.refresh_token);
      setData(res.data.listingAnnoucement);
    })();
  }, []);
    return (
    <>

    <section className="appbanner Adds padBottom scrollFade ">
            <div className="container">
            <div className="appbannerIn banners  text-center">
                {/* <h2 className="text-center fade1 titleh2">BTRL Exchange Coming Soon on</h2> */}
                <div className='playImages d-flex justify-content-center gap-2 mt-2'>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.btrl">
                        <img src='/latestimages/home/2.png' className="img-fluid" />
                    </a>
                    {/* 
                    <a href="#">
                        <img src='/latestimages/home/1.png' className="img-fluid" />
                    </a> 
                    */}
                </div>
            </div>
            </div>
        </section>
        {/*<section className="Partners padSec scrollFade ">
            <div className="container-fluid">

            <h2 className="text-center fade1 titleh2">Find Us On</h2>
            <div className=" boxanimation2 text-center">
                 <Swiper
                  loop={true}
                  speed={5000}
                  slidesPerView={states.swidth < 800 && states.swidth > 550 ? 3 : states.swidth < 550 ? 2 : 5}
                  modules={[Autoplay]}
                  className='' >
                    <SwiperSlide className="flex-center">
                        <img src={coinmarketcap} className="img-fluid" />
                    </SwiperSlide>
                    <SwiperSlide className="flex-center">
                        <img src={coinmarketcap1} className="img-fluid" />
                    </SwiperSlide>
                    <SwiperSlide className="flex-center">
                        <img src={athcoinindex} className="img-fluid" />
                    </SwiperSlide>
                    <SwiperSlide className="flex-center">
                        <img src={coingecko} className="img-fluid" />
                    </SwiperSlide>
                    <SwiperSlide className="flex-center">
                        <img src={cryptocoinworld} className="img-fluid" />
                    </SwiperSlide>

                </Swiper>
            </div>
            </div>
        </section>*/}
    </>
   );
};

export default Adds;